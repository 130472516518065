
import Vue from "vue";
import SecurityCode from "@/components/SignUp/SecurityCode.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "SignUpSecurityCode",
  components: {
    SecurityCode
  },
  data() {
    return {
      code: "",
      errors: {},
      btnLoading: false
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser"
    })
  },
  mounted(): void {
    if (!this.user.email) {
      this.$router.push({
        name: "SignUpPageMentoree"
      });
    }
  },
  methods: {
    onOtpCode() {
      this.btnLoading = true;
      this.$store
        .dispatch("otpcode/VERIFY_OTP", {
          code: this.code,
          email: this.user.email
        })
        .then(() => {
          this.register();
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    register() {
      this.$store
        .dispatch("auth/REGISTER_USER", this.user)
        .then(() => {
          this.btnLoading = false;

          this.$router.push("/register/profile-fill");

          // @ts-ignore
          this.$alertify.success(
            (this as any).$t("setting.signUpSuccessMessage")
          );
        })
        .catch(error => {
          this.btnLoading = false;

          if (error.response.status === 422) {
            for (const e of error.response.data.errors) {
              // @ts-ignore
              this.$alertify.error(e);
            }
          }
        });
    },
    hasError(type: string) {
      //@ts-ignore
      if (type in this.errors && this.errors[type][0].length > 0) {
        //@ts-ignore
        return { error: this.errors[type][0] };
      }
      return null;
    }
  }
});
